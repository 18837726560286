html{
  background-color: black;
  min-height: 100%;
  border: none;
}

.up-arrow{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eb2828;
}

.link {
  text-decoration: none;
  color: whitesmoke;
  margin: 0.5em;
}

.outer-wrapper{
  text-align: center;
  background-color: black;
  color: white;
  width: 100%;
  height: auto;
}

.outer-wrapper-white{
  text-align: center;
  background-color: white;
  color: black;
  width: 100%;
  height: auto;
}

.inner-wrapper{
  margin: 0 auto;
  width: 80%;
}



.content{
  margin: 0 auto;
  width: 80%;
}

.header-area{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 80%;
}

.drums-img{
  padding-bottom: 1em;
}

.drums-img img {
  width: 400px;
  z-index: -20;
  opacity: 0.6;
  -webkit-filter: grayscale(30%); /* Safari 6.0 - 9.0 */
  filter: grayscale(30%);
}


.about-img{
  width: 200px;
  bottom: 0;
  left: 0;
}

.about-img :hover{
  transform: scale(1.07);
  z-index: -4;
  offset: 10px;
}

.header-markus-hall {
  position: absolute;
  z-index: 10;
}



.projects{
  position: relative;
  padding: 2em 0;
}


.card-area {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
}

.projects-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:1em;
  padding: 1em;
  color: white;
  border-radius: 1em;
  background-color: rgb(0, 0, 0);
  box-shadow:
  4.7px 4.4px 8.4px -21px rgba(0, 0, 0, 0.164),
  32px 30px 37px -21px rgba(0, 0, 0, 0.29);

}



.about-me-area {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  margin: 1.5em 0;
  margin: 3em auto;
  width: 60%;
}

.text-left{
  text-align: left;
}

.header-content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  padding-top: 2em;
  width: 100%
}

.header-content-left {
  text-align: left;
}


.header-content-right {
  text-align: right;

}

.about-content-right {
  display: flex;
  align-items: flex-end;

}


.contact-content {
  padding: 2em 0;
  z-index: 1000;
}


.social-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1em;
  cursor: pointer;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out
}

.social-icons :hover {
  opacity: 0.6;
}

.insta{
  color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.projects-card-layout{
  margin: 0 auto;
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: solid gray 1px;
  border-radius: .5em;
  background-color: rgb(247, 247, 247);
  color: black;
}

.project-card-description{
  text-align: left;
  padding: 1em;
}

.project-card-image{
  border-radius: 1em;
  width: 100%;
  padding: 1em;
}

.gallery-area{
  display: grid;
  gap: .5em;
  grid-template-columns: 1fr 1fr 1fr;
}

.gallery-area img{
  width: 100%;
}

.gallery-item-double{
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
}

.gallery-item-single{
  display: flex;
  flex-direction: column;
}


.gallery-expand-buttons{
  background-color: rgb(31, 31, 31);
  color: white;
  border: solid 1px rgba(211, 211, 211, 0.769); 
  border-radius: 1em;
  cursor: pointer;
}

.gallery-expand-buttons :hover{
  transform: scale(1.1);
}

.image-gallery {
  display: block;
  padding: 0;
}

.image-gallery::after {
  content: "";
  flex-basis: 1em;
}


.image-gallery > li {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
}

.image-gallery li:hover .overlay {
  transform: scale(1);
}

.image-gallery li img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.1s 0.1s ease-in-out;
  color: #fff;
  border-radius: 5px;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}


.link-video-band :hover{
  cursor: pointer;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.bands-card-layout{
  display: grid;
  grid-template-columns: 1fr ;
  border: solid gray 1px;
  border-radius: .5em;
  background-color: rgb(247, 247, 247);
  color: black;
  margin: 0 auto;
  width: 70%;
}


.picture{
  width: 100%;
}

.latest-video-area{
  padding: 2em 0;
  text-align: center;
}

.favorite-img img{
  width: 100%;
  max-width: 100%;
}

.gallery-first-picture{
  margin: 0 auto;
  width: 60%;
  border-radius: 1em;
}

.contact{
  position: relative;
  background-color: black;
 
}

.contact::after {
  content: '';
  position: absolute;
  right: 0;
  left: -0%;
  top: 100%;
  z-index: 10;
  display: block;
  height: 50px;
  background-size: 50px 100%;
  background-image: linear-gradient(135deg, hsl(0, 0%, 0%) 25%, transparent 25%), linear-gradient(225deg, hsl(0, 0%, 0%) 25%, transparent 25%);
  background-position: 0 0;
}

.contact-white{
  position: relative;
  background-color: rgb(255, 255, 255);
 
}

.contact-white::after {
  content: '';
  position: absolute;
  right: 0;
  left: -0%;
  top: 100%;
  z-index: 10;
  display: block;
  height: 50px;
  background-size: 50px 100%;
  background-image: linear-gradient(135deg, hsl(0, 0%, 100%) 25%, transparent 25%), linear-gradient(225deg, hsl(0, 0%, 100%) 25%, transparent 25%);
  background-position: 0 0;
}



@media screen and (max-width: 480px) {
  .main{
    font-size: smaller;
  }

  .inner-wrapper{
    margin: 0 auto;
    width: 95%;
  }
  
  .content{
    margin: 0 auto;
    width: 95%;
  }

  .projects-card-layout{
    width: 100%;
  }
  

  .bands-card-layout{
    width: 100%;
  }
  
  .gallery-first-picture{
    margin: 0 auto;
    width: 100%;
  }
  
  .drums-img{
    width: 200px;

  }

  .content{
    width: 100%;
    padding: 0 1em;
  }

  .card-area{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;

  }

  .about-img{
    width: 100px;
  }

  .header-content {
    grid-template-columns: 3fr 1fr;
  }

  .projects-card-layout{
    grid-template-columns: 1fr;
    
  }

  .drums-img img {
    width: 200px;
    
  }

  .card-area {
    row-gap: 1em;
  }


  .gallery-area{
    grid-template-columns: 1fr;
  }

  .image-gallery {
    display: block;
    padding: 0;
  }

  
.image-gallery {
  display: block;
  padding: 0;
  height:fit-content;
}

.image-gallery > li {
  height: 5em;
  width: 100%;
}

}

@media screen and (max-width: 780px) {
  .content{
    width: 100%;
    margin: 0 0;
  }
  .image-gallery > li {
    height: 10em;
    width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  .image-gallery > li {
    height: 25em;
    width: 100%;
  }
  .content{
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1081px) {
  .content{
    width: 70%;
    margin: 0 auto;
  }
}
